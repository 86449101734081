import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import '../style/Navigation.css';

export default function Navigation() {
    const { t } = useTranslation();

    const [navbarOpen, setNavbarOpen] = useState(false)
    const handleToggle = () => {
      setNavbarOpen(prev => !prev)
    }
    const closeMenu = () => {
      setNavbarOpen(false)
    }
    return (
        <>
        <nav className="navBar">
            <button onClick={handleToggle}><i className={navbarOpen ? 'fas fa-times' : 'fas fa-bars'}/></button>
            <ul id='navigation' className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
                <li id='navigation-list'>
                    <NavLink exact to="/" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.about') }</NavLink>
                    <NavLink to="/info" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.info') }</NavLink>
                    <NavLink to="/wishlist" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.wishlist') }</NavLink>
                    <NavLink to="/rsvp" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.rsvp') }</NavLink>
                    <NavLink to="/speech" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.speech') }</NavLink>
                    <NavLink to="/photos" activeClassName="active-link" onClick={() => closeMenu()}>{ t('nav.photos') }</NavLink>
                </li>
            </ul>
        </nav>
    </>
    )
}