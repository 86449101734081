import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Wishlist(props) {
    const { t } = useTranslation();

    return (
        <>
            <h1 id='title'> { t('wishlist.title') } </h1>
            <div id="text-block" style={{padding: 0}}>
		        <p className="paragraph"> { t('wishlist.text') } </p>
	        </div>
            </>
    );
}