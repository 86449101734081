import React from 'react';
import { useTranslation } from 'react-i18next';
import '../style/Header.css';
import LanguageSelection from './LanguageSelection';
import Navigation from './Navigation';

export default function Header() {
    const { t } = useTranslation();

    return (
    	<>
    	<div id="top">
    	<LanguageSelection />
        <div id="header">
            <h1 id="names">Josefin &amp; Sebastian</h1>
            <p id="date">
                6<sup>{ t('header.sup') }</sup>{ t('header.nonSup') }
            </p>
        </div>
      	<Navigation />
      	</div>
        </>
    )
}