import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import '../style/Info.css';

export default function Info(props) {
	const { t } = useTranslation();

	useEffect(() => {
	  window.scrollTo(0, 0)
	}, [])
	
    return (
        <>
            <h1 id='title'>{ t('info.title') }</h1>
            <div id="text-block">
		        <p className="paragraph">
					<Trans i18nKey="info.p1">
						The day will take place at Kristinahuset (Nice Valley), <a href="https://www.google.com/maps/place/Nice+Valley+-+Kristinahuset/@59.249614,18.1176919,17z/data=!3m1!4b1!4m5!3m4!1s0x465f79a043a6ae61:0xc0538f9e16331c00!8m2!3d59.2496113!4d18.1198806" target='_blank' rel="noreferrer"> Herbert Widmans väg 7</a> in Sköndal.
					</Trans>
				</p>

				<p className="paragraph">{ t('info.p2') }</p>

				<p className="paragraph">{ t('info.p3') }</p>

				<p className="paragraph">{ t('info.p4') }</p>
	        </div>
            </>
    );
}