import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom'

import QrScanner from 'qr-scanner';

import '../style/Login.css';

const Login = withRouter(({ setter, history }) => {
	const { t } = useTranslation();

    function scanQrCode() {
        QrScanner.WORKER_PATH = 'scripts/qr-scanner-worker.min.js';

        const qrDisplay = document.getElementById('qr-display');
        qrDisplay.style.display = 'block';

        const scanner = new QrScanner(qrDisplay, url => {
            const parts = url.split('/');
            if (parts.length > 0) {
                const id = parts[parts.length - 1];

                history.push('rsvp/' + id);
                setter(id);
            }
        });
        scanner.start();
    }

    const LoginButton = () => (
        <button onClick={
            () => {
                let id = document.getElementById('code-input').value;
                history.push('rsvp/' + id);
                setter(id);
            }}>
                { t('login.loginButton') }
        </button>
    );

    return (
        <>
        <div id='login'>
            <div id='qr-login'>
                <video id='qr-display'></video>
                <button onClick={scanQrCode}> <img src='images/qr-code-icon.png' id='qr-icon' alt='qr-code'/> { t('login.qrPrompt') } </button>
            </div>
            <p>{ t('login.or') }</p>
            <div>
                <input
                    id='code-input'
                    type='text'
                    placeholder={ t('login.codePlaceholder') }
                />
                <LoginButton />
            </div>
        </div>
        </>
    )
});

export default Login;