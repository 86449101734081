const firebaseConfig = {

  apiKey: "AIzaSyBiia-aCoisqbg6lS6PuMOhmsNJqnaIT80",

  authDomain: "jossan-sebbe-2022.firebaseapp.com",

  projectId: "jossan-sebbe-2022",

  storageBucket: "jossan-sebbe-2022.appspot.com",

  messagingSenderId: "676417977317",

  appId: "1:676417977317:web:7f6daf640da7f45f250d0c"

};

export default firebaseConfig;