import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../style/Home.css';

export default function Home(props) {
	const { t } = useTranslation();
	
	useEffect(() => {
	  window.scrollTo(0, 0)
	}, [])

    return (
    	<>
            <h1 id='title'>{ t('home.title') }</h1>
            <div id="home-text-block">
				<img
				 	className='hero'
					src={process.env.PUBLIC_URL + '/images/proposal.jpg'}
					alt={ t('home.altText') }
				/>
				<div id="the-paragraphs">
            	<p className="home-paragraph">{ t('home.p1') }</p>

				<p className="home-paragraph">{ t('home.p2') }</p>

				<p className="home-paragraph">{ t('home.p3') }</p>
				</div>
            </div>
            </>
    );
}