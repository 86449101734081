import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Header from './components/Header';
import Info from './components/Info';
import Wishlist from './components/Wishlist';
import Rsvp from './components/Rsvp';
import Speech from './components/Speech';
import Photos from './components/Photos';
import Counter from './components/Counter';
import Home from './components/Home';

export default function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path='/info'>
          <Info />
        </Route>
        <Route path='/rsvp/:id?'>
          <Rsvp />
        </Route>
        <Route path='/photos'>
          <Photos />
        </Route>
        <Route path='/wishlist'>
          <Wishlist />
        </Route>
        <Route path='/speech'>
          <Speech />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
      <Counter />
    </Router>
  );
}