import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import '../style/Photos.css';
import { photoList } from '../photoLists';

export default function Photos(props) {
    const { t } = useTranslation();

	useEffect(() => {
	  window.scrollTo(0, 0)
	}, [])
	
    return (
        <>
            <h1 id='title'>{ t('photos.title') }</h1>
            <p id='photo-info'>{ t('photos.text') } </p>
            <div id='text-block'>
                <ul className='photo-list'>
                    { photoList.map(loc => <li> <a target="_blank" href={loc}> <img src={loc}/> </a> </li>) }
                </ul>
            </div>
        </>
    );
}