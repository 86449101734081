import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
            home: {
                title: "Our story",
                p1: "Josefin and Sebastian met for the first time in June 2012 at the community centre in Hallunda at the ninth graders' couple's dancing examination for all elementary schools in Tullinge.  The next time they met was after the summer break when they coincidentally started in the same high school class. They became best friends, they hung out everyday day and talked about everything.",
                p2: "Three years later, in 2015, they became a couple. Since then they have graduated together, embarked on their careers together, and traveled the world together. They have bought their first apartment together, gotten their wonderful cat together and now they are having their wedding, together with you.",
                p3: "Josefin and Sebastian have been together for seven years in April this year and will now start a new chapter of their life.",
                altText: "Photo taken of Josefin and Sebastian just minutes after the proposal."
            },
            login: {
                qrPrompt: "Scan your QR code",
                codePlaceholder: "Enter your code",
                loginButton: "Log in",
                or: "or"
            },
            counter: {
                title: "Days until the wedding",
                days: " days",
                hours: " hours",
                minutes: " minutes",
                seconds: " seconds"
            },
            info: {
                title: "All about the big day",
                p1: "The day will take place at Kristinahuset (Nice Valley), <1>Herbert Widmans väg 7</1> in Sköndal.",
                p2: "If the weather is nice the wedding will take place outdoors at 3:30pm followed by mingle on the terrass. Before we sit down for the dinner, a Persian ceremony will be held as well and those who want are welcome to participate.",
                p3: "There will be good food and beautiful scenery, followed by a great party at dusk.  Bring comfortable shoes because we will be dancing until the early morning.",
                p4: "We recommend that you bring sunblock if the weather is nice and that you remember to drink plenty of water. We do live in Sweden so bring an umbrella, just in case."
            },
            header: {
                sup: "th",
                nonSup: " of August 2022"
            },
            nav: {
                about: "About",
                info: "The wedding",
                wishlist: "Wishlist",
                rsvp: "RSVP",
                speech: "Speech",
                photos: "Photos"
            },
            wishlist: {
                text: "Your presence is all we ask for. Throughout the years we have collected more stuff than what we have room for. If you do want to honor our day with a gift we would greatly appreciate a contribution towards our honeymoon. In that case, you are welcome to swish Josefin at: 0704334939  or to IBAN SE59 3000 0000 0097 0703 4261, BIC NDEASESS.",
                title: "Wishlist"
            },
            rsvp: {
                title: "Let us know if you can make it!",
                description: "Here, you can RSVP to the wedding. Please let us know if you have any allergies, food preferences or something else we should take into consideration. Your answers can be updated until the 1 of June. Don't forget to submit your changes! ",
                form: {
                    save: "Saved",
                    changes: "Submit",
                    rsvp: "RSVP",
                    coming: "Coming",
                    notComing: "Can't make it",
                    foodpref: "Food preference",
                    email: "E-mail",
                    song: "A song I want to dance to"
                },
                prompt: "You have made changes that have not been saved yet. Are you sure you want to leave?"
            },
            speech: {
                title: "Dinner speeches",
                p1: "Want to give a speech during the dinner? How nice! Please contact our toastmasters at <1>josefinochsebastian@gmail.com</1>.",
                p2: "Please incorporate the following in your email:",
                p3: "A brief summary of what you want to talk about",
                p4: "If you are from the bride's or groom's side",
                p5: "An approximate duration of your speech",
                p6: "This will make putting together a schedule for the evening much easier for our toastmasters! Please keep in mind that a lot of guests may want to honor the bride and groom with a speech. Unfortunately all dinners have to end at some point and the toastmasters can't guarantee that everybody who wants to speak can fit in the schedule. They will let you know! "
            },
            photos: {
                title: "Photos",
                text: "Please remember to give credit to the photographer (Daniel Gual) if you use any of these photos."
            }
        }
      },
      sv: {
        translation: {
            home: {
                title: "Vår berättelse",
                p1: "Josefin och Sebastian träffades för första gången Juni 2012 i folkets hus i Hallunda på 9:ans pardans examination för alla grundskolor i Tullinge. Nästa gång dom träffades var efter sommarlovet när dom av ett sammanträffande började i samma klass i gymnasiet. Dom blev bästa vänner, umgicks varje dag och pratade om allt.",
                p2: "Tre år senare, 2015, blev dom ett par. Sen dess har dom tagit studenten tillsammans, tagit sig ut i arbetslivet tillsammans, och rest tillsammans. De har köpt sin första lägenhet tillsammans, skaffat sin fina fina katt tillsammans och nu ska dom gifta sig, tillsammans med er.",
                p3: "Josefin och Sebastian har varit tillsammans i 7 år i april detta året och påbörjar nu nästa kapitel i livet.",
                altText: "En bild på Josefin och Sebastian tagen bara några minuter efter frieriet."
            },
            login: {
                qrPrompt: "Skanna din QR-kod",
                codePlaceholder: "Skriv in din kod",
                loginButton: "Logga in",
                or: "eller"
            },
            counter: {
                title: "Dagar till bröllopet",
                days: " dagar",
                hours: " timmar",
                minutes: " minuter",
                seconds: " sekunder"
            },
            info: {
                title: "Allt om den stora dagen",
                p1: "Dagen kommer äga rum i Kristinahuset (Nice Valley), <1>Herbert Widmans väg 7</1> i Sköndal.",
                p2: "Vid fint väder kommer vigseln att hållas utomhus klockan 15:30 följt av mingel på terrassen. Innan vi alla slår oss ned för middag kommer även en persisk vigsel att hållas där dom som vill gärna får delta.",
                p3: "Det bjuds på god mat och härliga vyer som avslutas med en pangfest vid skymning. Ta med sköna skor för här ska det dansas ända in på småtimmarna.",
                p4: "Vi vill tipsa om att ta med solskydd vid fint väder och inte heller glömma att dricka vatten. Vi bor trots allt i Sverige så ett paraply är inte helt fel att ha med sig utifall att."
            },
            header: {
                sup: "",
                nonSup: ":e augusti 2022"
            },
            nav: {
                about: "Om oss",
                info: "Bröllopet",
                wishlist: "Önskelista",
                rsvp: "OSA",
                speech: "Hålla tal",
                photos: "Foton"
            },
            wishlist: {
                text: "Din närvaro är allt vi önskar. Genom åren har vi samlat på oss mer prylar än vad vi får plats med. Om ni ändå vill uppmärksamma vår dag med en gåva uppskattar vi ett bidrag till vår bröllopsresa. I så fall får ni gärna swisha Josefin: 0704334939 eller skicka pengar till IBAN SE59 3000 0000 0097 0703 4261, BIC NDEASESS.",
                title: "Önskelista"
            },
            rsvp: {
                title: "Låt oss veta om du kan komma!",
                description: "Här kan du O.S.A. till bröllopet. Berätta gärna om eventuella allergier, matpreferenser eller annat som vi behöver veta. Du kan ändra dina svar när som helst till och med 1 Juni. Glöm inte att spara dina ändringar!",
                form: {
                    save: "Sparat",
                    changes: "Skicka",
                    rsvp: "OSA",
                    coming: "Kommer",
                    notComing: "Kan tyvärr inte",
                    foodpref: "Matpreferenser",
                    email: "Mejl",
                    song: "En låt jag vill dansa till"
                },
                prompt: "Du har ändringar som inte är sparade. Är du säker på att du vill lämna sidan?"
            },
            speech: {
                title: "Hålla tal under middagen",
                p1: "Vill du hålla ett tal under middagen? Vad kul! Kontakta i så fall våra toastmasters på <1>josefinochsebastian@gmail.com</1>.",
                p2: "Vänligen bifoga följande i ditt mail:",
                p3: "En sammanfattning om vad du vill tala om",
                p4: "Om du är från brudens eller brudgummens sida",
                p5: "Ungefärligen hur långt talet kommer att vara",
                p6: "Detta kommer att underlätta för våra toastmasters när de sätter ihop ett schema för middagen! Många gäster kommer vilja hedra brudparet med ett tal, men dessvärre måste alla middagar ta slut vid något tillfälle. Därför kan inte toastmastrarna garantera att alla som vill hålla tal får plats i schemat. De kommer att höra av sig till dig som mailar dem och tackar er för er förståelse!"
            },
            photos: {
                title: "Foton",
                text: "Glöm inte att ge cred till fotografen (Daniel Gual) om du återanvänder någon av bilderna."
            }
        }
      }
    }
  });

export default i18n;
