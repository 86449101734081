import { t } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../style/Counter.css';

const timeOfWedding = new Date('2022/08/06 15:00'); // This is UTC

const secondDiv = 1000;
const minuteDiv = secondDiv * 60;
const hourDiv = minuteDiv * 60;
const dayDiv = hourDiv * 24;

const calculateTime = () => {
        let nowInMs = Date.now()
        let thenInMs = timeOfWedding.getTime()
        let diff = thenInMs - nowInMs;

        let days = Math.floor(diff / dayDiv);
        let hours = Math.floor((diff % dayDiv) / hourDiv);
        let minutes = Math.floor((diff % hourDiv) / minuteDiv);
        let seconds = Math.floor((diff % minuteDiv) / secondDiv);

        return {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
        }
    }

class Counter extends React.Component {
    constructor(props) {
        super(props);
        this.state = calculateTime();
    }

    tick() {
        this.setState(() => (calculateTime()));
    }

    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div id="counter">
                <h1 id="counter-title">{ t('counter.title') }</h1>
                <ul>
                <Value value={ Math.max(0, this.state.days) } unit="days" />
                <Value value={ Math.max(0, this.state.hours) } unit="hours" />
                <Value value={ Math.max(0, this.state.minutes) } unit="minutes" />
                <Value value={ Math.max(0, this.state.seconds) } unit="seconds" />
                </ul>
            </div>
        );
    }
}

function Value(props) {
	const { t } = useTranslation();

    return (
        <li id='counter-list'>
            <span className="time-value">{ props.value }</span>
            <br/>
            <span className="time-unit">{ t('counter.' + props.unit) }</span>
        </li>
    )
}

export default Counter;