import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Prompt } from 'react-router-dom';

import { initializeApp } from "firebase/app";  
import firebaseConfig from '../firebaseConfig';
import { getFirestore, getDoc, setDoc, doc, collection } from 'firebase/firestore/lite';
import '../style/Rsvp.css';

import Login from './Login';

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

async function getFriends(user) {
    if (user.friends) {
        return Promise.all(user.friends.map(async element => {
            return {'id': element.id, ...(await getDoc(element)).data()};
        }));
    } else {
        return Promise.resolve([]);
    }
}

function PersonForm({person, setter, gang, setDirty}) {
    const { t } = useTranslation();

    const updateValue = field => event => {
        setter({
            ...gang,
            [person.id]: {
                ...person, [field]: event.target.value
            }
        });
        setDirty(true);
    };

    return (
        <li key={person.id} className="guest-list">
         <h2 className="guest-name">{person.firstname} {person.lastname}</h2>   
         <div className='form-part'>
             <label>{ t('rsvp.form.rsvp') }:</label>
            <input
                type="radio"
                id={person.id + "-coming"}
                name={person.id + "-rsvp"}
                value="yes"
                onChange={updateValue('rsvp')}
                checked={person.rsvp === "yes"}
            />
            <label htmlFor={person.id + "-coming"}>{ t('rsvp.form.coming') }</label>
            <input
                type="radio"
                id={person.id + "-not-coming"}
                name={person.id + "-rsvp"}
                value="no"
                onChange={updateValue('rsvp')}
                checked={person.rsvp === "no"}
            />
            <label htmlFor={person.id + "-not-coming"}>{ t('rsvp.form.notComing') }</label>
         </div>
         <div className='form-part'>
            <label htmlFor={person.id + "-foodpref"}>{ t('rsvp.form.foodpref') }:</label>
            <input
                type="text"
                id={person.id + "-foodpref"}
                name={person.id + "-foodpref"}
                value={person.foodpref}
                onChange={updateValue('foodpref')}/>
         </div>
         <div className='form-part'>
            <label htmlFor={person.id + "-email"}>{ t('rsvp.form.email') }:</label>
            <input
                type="text"
                id={person.id + "-email"}
                name={person.id + "-email"}
                value={person.email}
                onChange={updateValue('email')}/>
         </div>
         <div className='form-part'>
            <label htmlFor={person.id + "-song"}>{ t('rsvp.form.song') }:</label>
            <input
                type="text"
                id={person.id + "-song"}
                name={person.id + "-song"}
                value={person.song}
                onChange={updateValue('song')}/>
         </div>
        </li>
    );
}

function GangForm({gang, setter, submitter, setDirty, dirty}) {
    const { t } = useTranslation();

    return (
        <>
        <Prompt when={dirty} message={t('rsvp.prompt')} />
        <div id="gang">
            <form onSubmit={submitter}>
                <ul id='guest'>
                    {
                        Object.keys(gang).map(personId => {
                            return (<PersonForm setDirty={setDirty} person={gang[personId]} setter={setter} gang={gang} />);
                        })
                    }

                </ul>
                <input id="submit-button" type="submit" value={ dirty ? t('rsvp.form.changes') : t('rsvp.form.save') } className={ dirty ? "dirty" : "clean" } />
            </form>
        </div>
        </>
    );
}

export default function Rsvp() {
    const { t } = useTranslation();

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    const baseState = { baseUser: {}, friends: [] }
    const [ guests, setGuests ] = useState(baseState);

    let initialId  = useParams().id;
    const [id, setId] = useState(initialId);

    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const baseUser = await getDoc(doc(db, 'guest', id));
                const friendUsers = await getFriends(baseUser.data())

                const baseUserData = {'id': baseUser.id, ...baseUser.data()};
                setGuests(Object.fromEntries([baseUserData, ...friendUsers].map(o => [o.id, o])));
            } catch {
                setId(null);
            }
        }

        fetchData();
    }, [id]);

    const updateDb = (event) => {
        const guestRef = collection(db, 'guest');
        Object.keys(guests).forEach(async guestId => {
            await setDoc(doc(guestRef, guestId), guests[guestId]);
        });

        event.preventDefault();
        setDirty(false);
    }

    const content = id ? <GangForm dirty={dirty} setDirty={setDirty} gang={guests} setter={setGuests} submitter={updateDb}/> : <Login setter={setId} />;

    return (
        <>
            <h1 id="title">{ t('rsvp.title') }</h1>
            <div id="intro">
            	<p id="info">
                    { t('rsvp.description') }
            	</p>
            </div>
            {
                content
            }
        </>
    );
}