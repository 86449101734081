import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

export default function Speech(props) {
    const { t } = useTranslation();

    return (
        <>
            <h1 id='title'> { t('speech.title') } </h1>
            <div id="text-block" style={{padding: 0}}>
		        	<p className="paragraph">
		        	<Trans i18nKey="speech.p1">
						Want to give a speech during the dinner? How nice! Please contact our toastmasters at <a href="mailto:josefinochsebastian@gmail.com" target='_blank' rel="noreferrer"> josefinochsebastian@gmail.com</a>.
					</Trans>
					</p>

					<p className="paragraph"><b>{ t('speech.p2') }</b></p>

					<li className="paragraph">{ t('speech.p3') }</li>
					<li className="paragraph">{ t('speech.p4') }</li>
					<li className="paragraph">{ t('speech.p5') }</li>

					<p className="paragraph">{ t('speech.p6') }</p>
	        </div>
            </>
    );
}