import React from 'react';
import { useTranslation } from 'react-i18next';
import '../style/LanguageSelection.css';

let flags = {
    'sv': '🇸🇪',
    'en': '🇺🇸',
    //'fa': '🇮🇷',
};

export default function LanguageSelection() {
    const { i18n } = useTranslation();

    return (
        <ul id="language-selection">
            {Object.keys(flags).map((lng) => (
            <li className='language-selection-list'>
                <button
                    className='languageButton'
                    key={lng}
                    type="submit"
                    onClick={() => i18n.changeLanguage(lng)}
                >
                    {flags[lng]}
                </button>
            </li>
          ))}
        </ul>
    );
}